// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-absolutely-tsx": () => import("./../../../src/pages/projects/absolutely.tsx" /* webpackChunkName: "component---src-pages-projects-absolutely-tsx" */),
  "component---src-pages-projects-hkfd-tsx": () => import("./../../../src/pages/projects/hkfd.tsx" /* webpackChunkName: "component---src-pages-projects-hkfd-tsx" */),
  "component---src-pages-projects-laura-lea-tsx": () => import("./../../../src/pages/projects/laura-lea.tsx" /* webpackChunkName: "component---src-pages-projects-laura-lea-tsx" */),
  "component---src-pages-projects-viq-tsx": () => import("./../../../src/pages/projects/viq.tsx" /* webpackChunkName: "component---src-pages-projects-viq-tsx" */)
}

